<template>
    <div class="page-managemessages">
        <b-card>
            <template #header>
                <div class="d-flex justify-content-between align-items-center" v-if="!loading">
                    <div>
                        <h5 class="headertitle">Respostas Pré-Definidas</h5>
                        <div class="headersubtitle">
                            Utilize as abas abaixo para configurar suas respostas
                        </div>
                    </div>
                    <div class="hstack gap-2 align-items-end">
                        <b-form-group label="Canal:" label-class="small-text text-secondary pb-1" v-if="secondaryChannels?.length">
                            <b-form-select class="rounded border-grey w-100" style="height: 36px;" v-model="selectedChannel">
                                <option :value="{ ...channel, channelConfig }">{{ user.channel?.channelNumber }}</option>
                                <option :value="channel" v-for="channel in secondaryChannels" :key="channel._id">{{ channel.channelNumber }}</option>
                            </b-form-select>
                        </b-form-group>
                        <b-button class="bg-pink border-0" v-b-modal.modalTags>Tags</b-button>
                    </div>
                </div>
                <div class="text-center" v-else>
                    <b-spinner />
                </div>
            </template>
            <div>
                <div class="answerdiv" v-b-toggle="`accordionAnswer1`">
                    <div class="d-flex align-items-center">
                        <span class="answernumber rounded-circle">1</span>
                        <h5 class="answertitle m-0">Atendimento Operacional</h5>
                    </div>
                    <div>
                        <b-icon class="answerdownicon" icon="chevron-down"></b-icon>
                    </div>
                </div>
                <b-collapse :id="`accordionAnswer1`" accordion="my-accordion" role="tabpanel">
                    <div class="bg-grey rounded p-3" v-if="channelConfig?.strings?.length">
                        <b-row class="m-0 mb-3 d-flex align-items-end">
                            <b-col class="p-0 pe-2">
                                <b-form-group label="Apresentação do Operador" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Mensagem padrão enviada ao contato quando o operador seleciona o protocolo para atendimento.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[3].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ps-2">
                                <b-form-group label="Atendimento Finalizado" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Quando um atendimento é finalizado.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[0].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-0 mb-3 d-flex align-items-end">
                            <b-col class="p-0 pe-2">
                                <b-form-group label="Encaminhamento entre Operadores" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Quando um atendimento é encaminhado de um operador para outro.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[4].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ps-2">
                                <b-form-group label="Resposta de Operadores Indisponíveis" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Quando um atendimento é encaminhado para um departamento, mas nenhum operador está online nesse departamento.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[5].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-0 mb-3 d-flex align-items-end">
                            <b-col class="p-0 pe-2">
                                <b-form-group label="Mensagem de tolerância" label-class="text-purple fw-bold p-0" v-if="channelConfig?.attToleranceTime && channelConfig.attToleranceTime > 0">
                                    <div class="text-purple small-text mb-2">
                                        <div>
                                            Mensagem padrão enviada para o contato quando envia mensagem estando dentro do tempo de tolerância.
                                        </div>
                                        <div>
                                            O contato respondendo com "1", o atendimento será aberto. Com "2", não abrirá novo atendimento.
                                        </div>
                                    </div>
                                    <b-form-textarea :placeholder="'Tem certeza de que deseja abrir um novo protocolo de atendimento?\n\n1 - Sim\n2 - Não'" v-model="selectedChannel.channelConfig.attToleranceMessage" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ps-2">

                            </b-col>
                        </b-row>
                    </div>
                    <div class="d-flex justify-content-center bg-grey rounded p-3" v-else>
                        <b-spinner></b-spinner>
                    </div>
                </b-collapse>
            </div>
            <div>
                <div class="answerdiv" v-b-toggle="`accordionAnswer2`">
                    <div class="d-flex align-items-center">
                        <span class="answernumber rounded-circle">2</span>
                        <h5 class="answertitle m-0">Auto-Atendimento</h5>
                    </div>
                    <div>
                        <b-icon class="answerdownicon" icon="chevron-down"></b-icon>
                    </div>
                </div>
                <b-collapse :id="`accordionAnswer2`" accordion="my-accordion" role="tabpanel">
                    <div class="bg-grey rounded p-3 mb-3" v-if="channelConfig?.strings.length">
                        <b-row class="m-0 mb-3 d-flex align-items-end">
                            <b-col class="p-0 pe-2">
                                <b-form-group label="Opção Inválida" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Mensagem padrão enviada ao contato quando seleciona uma opção inválida no menu de navegação.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[1].string" class="border-0 h-100" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ps-2" v-if="!channelConfig?.modules?.enableSimultaneousAttendanceAmongDifferentChannels">
                                <b-form-group label="Tentativa de Atendimento Simultâneo" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Mensagem padrão enviada ao contato quando o mesmo tenta entrar em contato com um canal, já estando em atendimento com outro canal.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[6].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-0 mb-3 d-flex align-items-end">
                            <b-col class="p-0 pe-2">
                                <b-form-group label="Protocolo Encaminhado para Atendente" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Mensagem padrão enviada ao contato quando o protocolo está sendo encaminhado para o atendente.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[2].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ps-2">
                                <b-form-group label="Protocolo Encaminhado após Auto Atendimento" label-class="text-purple fw-bold p-0">
                                    <div class="text-purple small-text mb-2">
                                        Mensagem padrão enviada ao contato quando o tempo em Auto Atendimento expira.
                                    </div>
                                    <b-form-textarea v-model="selectedChannel.channelConfig.strings[7].string" class="border-0" rows="5" max-rows="5" no-resize></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="d-flex justify-content-center bg-grey rounded p-3" v-else>
                        <b-spinner></b-spinner>
                    </div>
                </b-collapse>
            </div>
            <div v-if="channelConfig?.modules?.satisfactionSurvey && channelConfig.enableSatisfactionSurvey && !channelConfig.disableSatisfactionSurveyChannels.includes(selectedChannel._id)">
                <div class="answerdiv" v-b-toggle="`accordionAnswer3`">
                    <div class="d-flex align-items-center">
                        <span class="answernumber rounded-circle">3</span>
                        <h5 class="answertitle m-0">Pesquisa de Satisfação</h5>
                    </div>
                    <div>
                        <b-icon class="answerdownicon" icon="chevron-down"></b-icon>
                    </div>
                </div>
                <b-collapse :id="`accordionAnswer3`" accordion="my-accordion" role="tabpanel" v-if="channelConfig?.modules?.satisfactionSurvey && channelConfig?.enableSatisfactionSurvey">
                    <div class="bg-grey rounded mb-3">
                        <b-form-group label="Mensagem da Pergunta sobre a participação:" class="p-3 border-bottom border-white" label-class="text-purple fw-bold p-0 border-bottom">
                            <div class="text-purple small-text mb-2">
                                Crie sua pergunta da pesquisa sobre a participação.
                                <!-- <div>
                                    Mensagem perguntando para o contato se aceita ou não participar da pesquisa.
                                </div>
                                <div>
                                    É enviada ao contato quando o atendimento é finalizado.
                                </div> -->
                            </div>
                            <div class="position-relative">                          
                                <b-form-textarea ref="textareaSurvey1" v-model="selectedChannel.channelConfig.satisfactionSurveyEnabled.participationMessage" class="border-0 h-100" rows="5" max-rows="5" no-resize
                                :placeholder="'Gostaria de participar da Pesquisa de Satisfação?\nDigite a opção desejada:\n\n1 - Aceitar\n2 - Rejeitar'"
                                ></b-form-textarea>
                                <b-icon class="position-absolute end-0 bottom-0 translate-middle text-secondary darkenTextOnHover" @click="toggleDialogEmoji('survey1')" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmoji($event, 'survey1')" lang="pt-BR" v-show="showEmojiSurvey[0]" :i18n="i18n" />
                            </div>
                            <b-form-group label="Comandos:" class="mt-2 text-purple small-text" label-class="text-purple fw-bold p-0">
                                <span>
                                    1 - Aceita
                                </span>
                                <span class="ms-3">
                                    2 - Rejeita
                                </span>
                            </b-form-group>
                        </b-form-group>
                        <b-form-group label="Notas de Satisfação:" class="p-3 border-bottom border-white" label-class="text-purple fw-bold mb-2 p-0">
                            <div class="d-flex">
                                <span class="answernumber bg-purple text-white fs-5 fw-normal rounded-circle" v-for="item in ['01','02','03','04','05']" :key="item">{{ item }}</span>
                            </div>
                        </b-form-group>
                        <div>
                            <b-row class="m-0">
                                <b-col class="p-0">
                                    <b-form-group label="Mensagem da Resposta:" class="p-3 pb-2" label-class="text-purple fw-bold p-0">
                                        <div class="text-purple small-text mb-2">
                                            Crie sua mensagem da resposta positiva.
                                            <!-- <div>
                                                A pergunta da pesquisa.
                                            </div>
                                            <div>
                                                É enviada ao contato quando o mesmo aceita participar da Pesquisa.
                                            </div> -->
                                        </div>
                                        <div class="position-relative">                                
                                            <b-form-textarea ref="textareaSurvey2" v-model="selectedChannel.channelConfig.satisfactionSurveyEnabled.acceptedMessage" class="border-0 h-100" rows="5" max-rows="5" no-resize
                                            :placeholder="'Qual a sua nota para o nosso atendimento?\nDigite uma nota de 1 a 5:'"
                                            ></b-form-textarea>
                                            <b-icon class="position-absolute end-0 bottom-0 translate-middle text-secondary darkenTextOnHover" @click="toggleDialogEmoji('survey2')" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                            <VEmojiPicker @select="selectEmoji($event, 'survey2')" lang="pt-BR" v-show="showEmojiSurvey[1]" :i18n="i18n" />
                                        </div>
                                    </b-form-group>
                                    <b-form-group label="Mensagem da Resposta:" class="p-3 pt-1" label-class="text-purple fw-bold p-0">
                                        <div class="text-purple small-text mb-2">
                                            Crie sua mensagem da resposta negativa.
                                            <!-- Mensagem enviada quando o contato rejeita participar da pesquisa. -->
                                        </div>
                                        <div class="position-relative">                                
                                            <b-form-textarea ref="textareaSurvey3" v-model="selectedChannel.channelConfig.satisfactionSurveyEnabled.deniedMessage" class="border-0 h-100" rows="5" max-rows="5" no-resize
                                            :placeholder="'Que pena 😢! Obrigado!'"
                                            ></b-form-textarea>
                                            <b-icon class="position-absolute end-0 bottom-0 translate-middle text-secondary darkenTextOnHover" @click="toggleDialogEmoji('survey3')" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                            <VEmojiPicker @select="selectEmoji($event, 'survey3')" lang="pt-BR" v-show="showEmojiSurvey[2]" :i18n="i18n" />
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col class="p-0">
                                    <b-form-group label="Mensagem de Conclusão:" class="p-3" label-class="text-purple fw-bold p-0">
                                        <div class="text-purple small-text mb-2">
                                            Crie sua mensagem de agradecimento.
                                        </div>
                                        <div class="position-relative">                                
                                            <b-form-textarea ref="textareaSurvey4" v-model="selectedChannel.channelConfig.satisfactionSurveyEnabled.conclusionMessage" class="border-0 h-100" rows="13" max-rows="13" no-resize
                                            :placeholder="'Obrigado pela sua resposta!'"
                                            ></b-form-textarea>
                                            <b-icon class="position-absolute end-0 bottom-0 translate-middle text-secondary darkenTextOnHover" @click="toggleDialogEmoji('survey4')" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                            <VEmojiPicker @select="selectEmoji($event, 'survey4')" lang="pt-BR" v-show="showEmojiSurvey[3]" :i18n="i18n" />
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-center bg-grey rounded p-3" v-else>
                        <b-spinner></b-spinner>
                    </div> -->
                </b-collapse>
            </div>
            <div v-if="channelConfig?.disableOfflineOperators">
                <div class="answerdiv" v-b-toggle="`accordionAnswer4`">
                    <div class="d-flex align-items-center">
                        <span class="answernumber rounded-circle"> {{ channelConfig?.modules?.satisfactionSurvey && channelConfig?.enableSatisfactionSurvey && !channelConfig.disableSatisfactionSurveyChannels.includes(selectedChannel._id) ? 4 : 3 }}</span>
                        <h5 class="answertitle m-0">Outras Mensagens</h5>
                    </div>
                    <div>
                        <b-icon class="answerdownicon" icon="chevron-down"></b-icon>
                    </div>
                </div>
                <b-collapse :id="`accordionAnswer4`" accordion="my-accordion" role="tabpanel">
                    <div class="bg-grey rounded mb-3">
                        <b-form-group label="Resposta de Operadores Indisponíveis:" class="p-3 border-bottom border-white" label-class="text-purple fw-bold p-0 border-bottom mb-1">
                            <div class="position-relative">
                                <b-form-textarea v-model="selectedChannel.channelConfig.disableOfflineOperatorsMessage" class="border-0 h-100" rows="5" max-rows="5" no-resize
                                placeholder="Nosso horário de atendimento é das 08 hs às 18 hs de segunda à sexta-feira."
                                ></b-form-textarea>
                            </div>
                        </b-form-group>
                    </div>
                </b-collapse>
            </div>
        </b-card>
        <div class="bg-white p-4 rounded d-flex justify-content-center mb-4">
            <b-button class="me-2 text-white" variant="green" @click="updateChannelConfig()">Salvar</b-button>
            <b-button class="border" variant="secondary-outline" @click="goToMain" v-b-modal.modal-tags>Cancelar</b-button>
        </div>
        <b-modal id="modalTags" ref="modalTags" title="Tags" header-class="py-0" body-class="p-0" hide-footer>
            <div class="border-bottom py-2 px-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import { VEmojiPicker } from 'v-emoji-picker';

export default {
    props: [
        'user',
        'i18n'
    ],
    components:{
        VEmojiPicker
    },
    data() {
        return {
            channelConfig: null,
            channel: null,
            secondaryChannels: [],
            showEmojiSurvey: [false, false, false, false],
            selectedChannel: {},
            loading: false,
            tags: [
                {
                    name: '%DTN%',
                    description: 'Dia, Tarde ou Noite',
                },
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: "EMAIL_CLIENTE",
                    description: "Email do Contato"
                },
                {
                    name: "CODIGO_CLIENTE",
                    description: "Código do Contato"
                },
                {
                    name: '%NOME_DEPARTAMENTO%',
                    description: 'Identifica nome do departamento (caso o contato esteja em atendimento com um departamento ou operador)',
                },
                {
                    name: '%OPERADOR%',
                    description: 'Identifica nome do operador (caso o contato esteja em atendimento com um operador)',
                },
                {
                    name: '%APELIDO%',
                    description: 'Identifica apelido do operador',
                },
                {
                    name: '%NUMERODOCANAL%',
                    description: 'Identifica número do canal',
                },
                {
                    name: '%PROTOCOLO%',
                    description: 'Identifica protocolo de atendimento',
                },
            ],
        }
    },
    mounted: function() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true

            await this.getChannel()
            await this.getChannelConfig()

            this.loading = false

            await this.getSecondaryChannels()
        },
        async getChannel() {
            const resp = await api.getChannel(this.user.channelId || this.user.roleId)
            console.log({resp})
            if(resp?.statusCode === 200) {
                this.channel = resp.channel.channel
                this.selectedChannel = { ...resp.channel.channel }
            }
        },
        async getChannelConfig() {
            const resp = await api.getChannelConfig(this.user.channelId || this.user.roleId)

            if(resp?.statusCode === 200) {
                this.channelConfig = resp.channelConfig
                this.selectedChannel.channelConfig = resp.channelConfig
            }
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.secondaryChannels = []
            } else {
                this.secondaryChannels = resp.channels.sort((a, b) => a.channelNumber.localeCompare(b.channelNumber))
                this.secondaryChannels.map(async (el) => {
                    const config = await api.getChannelConfig(el._id)
                    this.$set(el,'channelConfig',config.channelConfig)
                    return el
                })
            }
        },
        async updateChannelConfig() {
            const resp = await api.updateChannelConfig(this.selectedChannel.channelConfig)
            console.log('save',resp)
            let msg = {}
            if(resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro, tente novamente mais tarde!',
                    success: false,
                }
            } else {
                msg = {
                    text:'Respostas alteradas com sucesso!',
                    success: true,
                }
            }
            this.$emit('msg',msg)
            // if(this.secondaryChannels?.length) {
            //     this.secondaryChannels.forEach(async (el) => {
            //         if(el.channelConfig) {
            //             if(this.channelConfig.strings)
            //                 el.channelConfig.strings = this.channelConfig.strings
            //             await api.updateChannelConfig(el.channelConfig)
            //         }
            //     })
            // }
        },
        goToMain() {
            this.$router.replace({path:'/'})
        },
        toggleDialogEmoji(textareaId) {
            switch(textareaId) {
                case 'survey1':
                    this.showEmojiSurvey[0] = !this.showEmojiSurvey[0]
                    break
                case 'survey2':
                    this.showEmojiSurvey[1] = !this.showEmojiSurvey[1]
                    break
                case 'survey3':
                    this.showEmojiSurvey[2] = !this.showEmojiSurvey[2]
                    break
                case 'survey4':
                    this.showEmojiSurvey[3] = !this.showEmojiSurvey[3]
                    break
            }
            this.$set(this.showEmojiSurvey,0,this.showEmojiSurvey[0])
        },
        selectEmoji(emoji, textareaId) {
            let textarea = null

            switch(textareaId) {
                case 'survey1':
                    textarea = this.$refs.textareaSurvey1
                    break
                case 'survey2':
                    textarea = this.$refs.textareaSurvey2
                    break
                case 'survey3':
                    textarea = this.$refs.textareaSurvey3
                    break
                case 'survey4':
                    textarea = this.$refs.textareaSurvey4
                    break
            }

            if(textarea) {
                const sentence = textarea.vModelValue
                const len = sentence.length
                let pos = textarea.selectionStart
                if (pos === undefined) {
                    pos = 0
                }
    
                const before = sentence.substr(0, pos)
                const after = sentence.substr(pos, len)

                switch(textareaId) {
                    case 'survey1':
                        this.channelConfig.satisfactionSurveyEnabled.participationMessage = before + emoji.data + after
                        break
                    case 'survey2':
                        this.channelConfig.satisfactionSurveyEnabled.acceptedMessage = before + emoji.data + after
                        break
                    case 'survey3':
                        this.channelConfig.satisfactionSurveyEnabled.deniedMessage = before + emoji.data + after
                        break
                    case 'survey4':
                        this.channelConfig.satisfactionSurveyEnabled.conclusionMessage = before + emoji.data + after
                        break
                }
    
                this.toggleDialogEmoji(textareaId)
            }
        },
    },
}
</script>

<style>
    .page-managemessages .headertitle{
        color: #333;
    }
</style>

<style scoped>
    .page-managemessages{
        padding-bottom: 0;
    }
    .page-managemessages .card{
        border:none;
        margin-bottom: 30px;
    }
    .card-header{
        background-color: #fff;
        padding: 25px;
    }
    .card-body{
        padding: 10px 25px;
    }
    .headersubtitle{
        color: #888;
        line-height: 0.5em;
        font-size: 0.9em;
    }
    .answerdiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: hsl(232deg 37% 62%);
        border-radius: 8px;
        padding: 10px 20px;
        margin: 20px 0;
    }
    .answerdiv:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .answernumber{
        margin-right: 15px;
        background-color: #fff;
        color: hsl(232deg 37% 62%);
        display:flex;
        align-items: center;
        font-size: 1.1em;
        font-weight: 1000;
    }
    .answernumber:before,
    .answernumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;
        padding: 50% 0;
    }
    .answernumber:before {
        padding-left:8px;
    }
    .answernumber:after {
        padding-right:8px;
    }
    .answertitle{
        color: #fff;
    }
    .answerdownicon{
        color: #fff;
        cursor: pointer;
        font-size: 1.25em;
    }
    .btngreen:not(:last-child) {
        margin-right: 10px;
    }
    .btngreen{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
    .btnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .cardbtns{
        padding: 25px 0;
    }
    .answerdivdrop{
        position: relative;
        /* display: none; */
    }
    .answerdivdropcontent{
        position: absolute;
        display: block;
        background-color: #fff;
        top: 200px;
    }
    #modalTags div:last-child{
        border-bottom: none !important;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    @media(max-width:375px) {
        .headersubtitle{
            line-height: 1em;
        }
    }
</style>